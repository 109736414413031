"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignInSchema = exports.UpdateBuyerVerificationValidationSchema = exports.UpdateBuyerValidationSchema = exports.SearchOrderValidationSchema = exports.CreateDeviceValidationSchema = exports.REQUIRED_FIELD = exports.MUST_BE_VALID_EMAIL = exports.MUST_BE_VALID_HP = exports.INTERNAL_ERROR = void 0;
const Yup = __importStar(require("yup"));
exports.INTERNAL_ERROR = 'Erro interno. Por favor, entre em contato com o suporte.';
exports.MUST_BE_VALID_HP = 'Este campo deve ser um HP válido';
exports.MUST_BE_VALID_EMAIL = 'Este campo deve ser um e-mail válido';
exports.REQUIRED_FIELD = 'Este campo é obrigatório';
exports.CreateDeviceValidationSchema = Yup.object({
    build_id: Yup.string().required(),
    device_info: Yup.mixed().required(),
    push_token: Yup.string().nullable().required()
});
exports.SearchOrderValidationSchema = Yup.object({
    transaction: Yup.string().test({
        test: value => {
            const hasHP = value === null || value === void 0 ? void 0 : value.trim().startsWith('HP');
            if (!hasHP) {
                const isNumeric = Number(value);
                return !Number.isNaN(isNumeric);
            }
            const hasNumbers = value === null || value === void 0 ? void 0 : value.trim().replace(/\D/ig, '');
            if (!(hasNumbers === null || hasNumbers === void 0 ? void 0 : hasNumbers.length)) {
                return false;
            }
            return true;
        },
        message: exports.MUST_BE_VALID_HP
    }).required(exports.REQUIRED_FIELD)
});
exports.UpdateBuyerValidationSchema = Yup.object({
    name: Yup.string().required(exports.REQUIRED_FIELD),
    email: Yup.string().email(exports.MUST_BE_VALID_EMAIL).required(exports.REQUIRED_FIELD),
    document: Yup.string().test({
        message: 'Deve ser um CPF válido',
        test: (value) => {
            const cpf = (value === null || value === void 0 ? void 0 : value.replace(/[^\d]+/g, '')) || '';
            if (cpf == '') {
                return false;
            }
            if (cpf.length != 11 ||
                cpf == "00000000000" ||
                cpf == "11111111111" ||
                cpf == "22222222222" ||
                cpf == "33333333333" ||
                cpf == "44444444444" ||
                cpf == "55555555555" ||
                cpf == "66666666666" ||
                cpf == "77777777777" ||
                cpf == "88888888888" ||
                cpf == "99999999999")
                return false;
            let add = 0;
            for (let i = 0; i < 9; i++)
                add += parseInt(cpf.charAt(i)) * (10 - i);
            let rev = 11 - (add % 11);
            if (rev == 10 || rev == 11)
                rev = 0;
            if (rev != parseInt(cpf.charAt(9)))
                return false;
            add = 0;
            for (let i = 0; i < 10; i++)
                add += parseInt(cpf.charAt(i)) * (11 - i);
            rev = 11 - (add % 11);
            if (rev == 10 || rev == 11)
                rev = 0;
            if (rev != parseInt(cpf.charAt(10)))
                return false;
            return true;
        }
    }).required(exports.REQUIRED_FIELD),
    address_cep: Yup.string().required(exports.REQUIRED_FIELD),
    address_street: Yup.string().required(exports.REQUIRED_FIELD),
    address_number: Yup.string().required(exports.REQUIRED_FIELD),
    address_neighborhood: Yup.string().required(exports.REQUIRED_FIELD),
    address_complement: Yup.string().optional(),
    address_city: Yup.string().required(exports.REQUIRED_FIELD),
    address_state: Yup.string().length(2, 'Deve conter apenas 2 letras').required(exports.REQUIRED_FIELD),
    address_country: Yup.string().required(exports.REQUIRED_FIELD),
});
exports.UpdateBuyerVerificationValidationSchema = Yup.object({
    external_id: Yup.string().required(exports.REQUIRED_FIELD),
    token: Yup.string().required(exports.REQUIRED_FIELD),
});
exports.SignInSchema = Yup.object({
    password: Yup.string().required(exports.REQUIRED_FIELD),
});
