"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PROGRAMS = void 0;
exports.PROGRAMS = [
    {
        id: 'LATAMPASS',
        name: 'Latampass',
        keywords: ['Latam', 'Latampass'],
        icon: 'https://latampass.latam.com/build-red/latam-pass.6a3b023e.png'
    },
    {
        id: 'SMILES',
        name: 'Smiles',
        keywords: ['Smiles', 'Gol'],
        icon: 'https://images.kiwi.com/airlines/64/G3.png'
    },
    {
        id: 'TAP',
        name: 'TAP',
        keywords: ['TAP', 'Miles & Go'],
        icon: 'https://images.kiwi.com/airlines/64/TP.png'
    },
    {
        id: 'TUDOAZUL',
        name: 'Azul',
        keywords: ['Azul', 'Tudo Azul'],
        icon: 'https://images.kiwi.com/airlines/64/AD.png'
    },
    {
        id: 'AZUL_INTERLINE',
        name: 'Azul Interline',
        keywords: ['Interline', 'Azul Interline'],
        icon: 'https://images.kiwi.com/airlines/64/AD.png'
    },
    {
        id: 'IBERIA_PLUS',
        name: 'Iberia',
        keywords: ['Iberia Plus', 'Iberiaplus', 'Iberia'],
        icon: 'https://images.kiwi.com/airlines/64/IB.png'
    },
    {
        id: 'AADVANTAGE',
        name: 'AAdvantage',
        keywords: ['AA', 'American', 'Advantage', 'AAdvantage'],
        icon: 'https://images.kiwi.com/airlines/64/AA.png'
    },
    {
        id: 'PRIVILEGE_CLUB',
        name: 'Privilege Club',
        keywords: ['Privilege', 'Qatar'],
        icon: 'https://images.kiwi.com/airlines/64/QR.png'
    },
    {
        id: 'AEROPLAN',
        name: 'Aeroplan',
        keywords: ['Air Canada', 'AirCanada', 'Aeroplan'],
        icon: 'https://images.kiwi.com/airlines/64/AC.png'
    }
];
